import React, { useEffect, useState } from 'react';
import './Steps.css';
import '../Core.css';
import MyDataHelps from '@careevolution/mydatahelps-js';
import { useInitializeView } from '../helpers/Initialization';
import moment, { Moment } from 'moment';
import Chart, { ChartDataSeries } from '../components/Chart';
import NavigationBar from '../components/NavigationBar';
export default function Steps() {
    const [chartData, setChartData] = useState<ChartDataSeries[] | undefined>([]);
    const [nextPage, setNextPage] = useState<string | undefined>(undefined);
    const [fitbitDataAvailable, setFitbitDataAvailable] = useState(true);
    const [nextApplePage, setNextApplePage] = useState<string | undefined>(undefined);
    const [chartAppleData, setChartAppleData] = useState<ChartDataSeries[] | undefined>([]);

    const today = moment().startOf('day');
    const yearAgo = moment().startOf('day').subtract(1, 'years');
    const monthAgo = moment().startOf('day').subtract(1, 'months');

    useInitializeView(() => {
        // FITBIT data pull. Prioritize this over Apple HealthKitV2
        MyDataHelps.queryFitbitDailySummaries({
            startDate: `${yearAgo.format('YYYY-MM-DD')} 00:00:00`,
            endDate: `${today.format('YYYY-MM-DD')} 23:59:59`,
            pageSize: 25,
        })
            .then((fitBitResponse) => {
                if(fitBitResponse == null) {
                    // Set the Fitbit data availability flag
                    setFitbitDataAvailable(false);
                    setChartAppleData([
                        {
                            id: '',
                            color: '#00274c',
                            entries: [],
                        },
                    ]);
                    return;
                }
                const entries = fitBitResponse.results.map((d) => ({
                    date: moment(d.date).startOf('day'),
                    value: d.steps,
                }));

                setChartData([
                    {
                        id: '',
                        color: '#00274c',
                        entries,
                    },
                ]);
                setNextPage(fitBitResponse.nextPageID?.toString());
            });
    });

    // FOR FITBIT: Need to get the other pages of data up to 365 days. Max calls are 15 total to get this data
    useEffect(() => {
        if (nextPage != null) {
            MyDataHelps.queryFitbitDailySummaries({
                pageID: nextPage,
                startDate: `${yearAgo.format('YYYY-MM-DD')} 00:00:00`,
                endDate: `${today.format('YYYY-MM-DD')} 23:59:59`,
                pageSize: 25,
            }).then((r) => {
                const entries = r.results.map((d) => ({
                    date: moment(d.date).startOf('day'),
                    value: d.steps,
                }));
                setChartData((c) => {
                    if (c) {
                        c[0].entries = c[0].entries.concat(entries);
                        return c;
                    }
                    return [
                        {
                            id: '',
                            color: '#00274c',
                            entries,
                        },
                    ];
                });
                if (r.nextPageID != null) {
                    setNextPage(r.nextPageID.toString());
                }
            });
        }
    }, [nextPage]);

    useEffect(() => {
        if (!fitbitDataAvailable) {
            // Query Apple Health data when Fitbit data is not available
            MyDataHelps.queryDeviceData({
                namespace: 'AppleHealth',
                type: 'Steps',
                observedBefore: `${today.format('YYYY-MM-DD')} 23:59:59`,
                observedAfter: `${monthAgo.format('YYYY-MM-DD')} 00:00:00`,
                pageID: nextApplePage
            }).then((appleResponse) => {
                // Filter the dataArray to include only "Apple Watch" data
                const dataArray = appleResponse.deviceDataPoints;
                const stepsByDateData: { [key: string]: number } = {};

                dataArray.forEach((entry) => {
                    if (
                        entry.source &&
                        entry.source.properties &&
                        entry.source.properties['DeviceName'] === 'Apple Watch'
                    ) {
                        const steps = parseFloat(entry.value);
                        const date = moment(entry.observationDate);

                        if (!isNaN(steps)) {
                            if (!stepsByDateData[date.format('YYYY-MM-DD')]) {
                                stepsByDateData[date.format('YYYY-MM-DD')] = 0;
                            }
                            stepsByDateData[date.format('YYYY-MM-DD')] += steps;
                        }
                    }
                });

                setChartAppleData((c) => {
                    if (c) {
                        const entries = c[0].entries;
                        Object.keys(stepsByDateData).forEach((entry) => {
                            const steps = stepsByDateData[entry];
                            const foundIndex = entries.findIndex(oldEntry => oldEntry.date.format('YYYY-MM-DD') === entry);
                            if(foundIndex >= 0) {
                                if(entries[foundIndex] != null && entries[foundIndex].value != null) {
                                    // @ts-ignore
                                    entries[foundIndex].value += steps;
                                }
                            } else {
                                entries.push({
                                    date: moment(entry),
                                    value: steps
                                })
                            }
                        });
                        c[0].entries = entries;
                    }
                    return c;
                });
                if (appleResponse.nextPageID != null) {
                    setNextApplePage(`${appleResponse.nextPageID}`);
                }
            });
        }
    }, [nextApplePage,fitbitDataAvailable]);

    return (
        <div className="steps view">
            <NavigationBar />
            <div className="title">Steps</div>
            <div className="content-container">
                {fitbitDataAvailable && (
                    <div>
                        <h1>Fitbit</h1>
                        <Chart data={chartData} yAxisLabel="Step Count" />
                    </div>
                )}
                {!fitbitDataAvailable && chartAppleData && (
                    <div>
                        <h1>Apple Watch</h1>
                        <Chart data={chartAppleData} yAxisLabel="Step Count" />
                    </div>
                )}
            </div>
        </div>
    );
}