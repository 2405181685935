import React from 'react'
import './Tasks.css';
import '../Core.css';
import {SurveyTaskList} from '@careevolution/mydatahelps-ui';

export default function () {
    return (
        <div className="tasks">
            <div className="tasks-header">
                <img className="tasks-logo-icon" src="images/logo-icon.svg" alt="logo icon"/>
                <img className="tasks-logo" src="images/logo1.svg" alt="logo"/>
                <div/>
            </div>
            <div className="tasks-content view">
                <div className="title">Welcome back!</div>
                <div className="subtitle">Start your daily surveys</div>
                <div className="tasks-lists content-container">
                    <SurveyTaskList title="Incomplete Tasks" status="incomplete"/>
                    <SurveyTaskList title="Completed Tasks" status="complete" limit={3}/>
                </div>
            </div>
        </div>
    )
}