import MyDataHelps, { Guid, SurveyAnswer, SurveyAnswersQuery } from '@careevolution/mydatahelps-js';
import moment from 'moment';

// Define a function to check if the participant is enrolled for more than 28 days
async function isEnrolledMoreThan28Days(): Promise<boolean> {
    const participantInfo = await MyDataHelps.getParticipantInfo();
    if (participantInfo) {
        const enrollmentDate = participantInfo.enrollmentDate;
        const currentDate = new Date();
        const timeDifference = +currentDate - +new Date(enrollmentDate);
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference > 28;
    }
    return true;
}

export function loadSurveyAnswers(queryParameters: SurveyAnswersQuery): Promise<SurveyAnswer[]> {
    return MyDataHelps.querySurveyAnswers(queryParameters)
        .then(response => response.surveyAnswers);
}

export async function loadDailyRatingSurveyAnswers(stepIdentifier: string | undefined = undefined): Promise<SurveyAnswer[]> {
    // Create an array to hold the combined survey answers
    let combinedSurveyAnswers: SurveyAnswer[] = [];

    // Check if the participant is enrolled for more than 28 days
    const enrolled = await isEnrolledMoreThan28Days();

    if (enrolled) {
        // Retrieve daysDifference considering days > 28
        const participantInfo = await MyDataHelps.getParticipantInfo();
        const enrollmentDate = participantInfo.enrollmentDate;
        const currentDate = new Date();
        const timeDifference = +currentDate - +new Date(enrollmentDate);
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // Calculate the number of days to go back, ensuring it's not more than 56(84-28) days
        const daysToGoBack = Math.min(daysDifference - 28, 56);
        
        // Load 'Daily Assessments (Day 29-84)' survey answers
        const survey29to84Answers = await loadSurveyAnswers({
            surveyName: 'Daily Assessments (Day 29-84)',
            after: moment().startOf('day').add(-daysToGoBack, 'days').toISOString(),
            stepIdentifier,
        });

        // Load 'Daily Assessments (Day 0-28)' survey answers
        const survey0to28Answers = await loadSurveyAnswers({
            surveyName: 'Daily Assessments (Day 0-28)',
            after: moment().startOf('day').add(-28, 'days').toISOString(),
            stepIdentifier,
        });

        // Append both sets of survey answers
        combinedSurveyAnswers = survey29to84Answers.concat(survey0to28Answers);
        console.log(combinedSurveyAnswers);
    } else {
        // Participant is enrolled for 28 days or less, return 'Daily Assessments (Day 0-28)' survey answers
        const survey0to28Answers = await loadSurveyAnswers({
            surveyName: 'Daily Assessments (Day 0-28)',
            after: moment().startOf('day').add(-28, 'days').toISOString(),
            stepIdentifier,
        });

        combinedSurveyAnswers = survey0to28Answers;
    }

    return combinedSurveyAnswers;
}
