import React, { useState } from 'react'
import './Dashboard.css';
import '../Core.css';
import MyDataHelps, { ParticipantInfo, SurveyAnswersPage, SurveyAnswersQuery } from '@careevolution/mydatahelps-js';
import { LoadingIndicator } from '@careevolution/mydatahelps-ui';
import Cta from '../components/Cta';
import { useInitializeView } from "../helpers/Initialization";

export default function () {

    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
    const [showInfographics, setShowInfographics] = useState<boolean>(false);
    const [IsEnrolled28, setIsEnrolled28] = useState<boolean>(false);
    

    useInitializeView(() => {
        MyDataHelps.getParticipantInfo().then(participantInfo => {
            const enrollmentDate = participantInfo == null ? 'backupval' : participantInfo.enrollmentDate;
            const currentDate = new Date();
            console.log(enrollmentDate);
            const timeDifference = +new Date(currentDate.valueOf()) - +new Date(enrollmentDate.valueOf());
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            setIsEnrolled28(daysDifference >= 28);
            console.log("daysDifference", daysDifference);
            setParticipantInfo(participantInfo);
            console.log(participantInfo)
        });
     
        let surveyAnswersQuery: SurveyAnswersQuery = {
            surveyName: ['Feasibility_Visit1 (Baseline)', 'Edu_Visit1'],
            limit: 1
        };
        MyDataHelps.querySurveyAnswers(surveyAnswersQuery).then((result: SurveyAnswersPage) => {
            setShowInfographics(result.surveyAnswers.length > 0);
        });
    });
    



    const isSymptomTracked = (symptom: string) => {
        let customFields = participantInfo?.customFields;
        return customFields && customFields.hasOwnProperty(symptom) && customFields[symptom] === "True";
    };

    const onModuleClicked = (module: string) => {
        MyDataHelps.openApplication(new URL(module, document.location as any).href, {modal: true});
    };

    const onInfographicsClicked = () => {
        MyDataHelps.openApplication(new URL('infographics', document.location as any).href, {modal: true});
    };

    const onViewDailyLog = () => {
        MyDataHelps.openApplication(new URL('https://www.conquerlupus.com/', document.location as any).href, {modal: true});
    };

    
    return (
        <div className="dashboard view">
            <div className="title">Review your data</div>
            {!participantInfo &&
            <LoadingIndicator/>
            }
            {participantInfo &&
            <div className="dashboard-toc">
                <Cta icon="images/pain.svg" text="Pain" onClick={() => onModuleClicked('pain-interim')}/>
                <Cta icon="images/fatigue.svg" text="Fatigue" onClick={() => onModuleClicked('fatigue')}/>
                <Cta icon="images/mental.svg" text="Mental Health and Mood" onClick={() => onModuleClicked('mood-interim')}/>
                <Cta icon="images/steps.svg" text="Steps" onClick={() => onModuleClicked('steps')}/>
                {showInfographics &&
                <Cta icon="images/info.svg" className="cta-fa" text="Infographics" onClick={() => onInfographicsClicked()}/>
                }
                {participantInfo && IsEnrolled28 &&
                <div className="link-to-lupus-website" onClick={() => onViewDailyLog()}>Link to Lupus Website</div>
                }
            </div>
            }
        </div>
    )
}