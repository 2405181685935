import React, {useState} from 'react'
import './Fatigue.css';
import '../Core.css';
import {SurveyAnswer} from '@careevolution/mydatahelps-js';
import Chart from '../components/Chart';
import {useInitializeView} from '../helpers/Initialization';
import {buildRatingChartDataSeries} from '../helpers/ChartData';
import {loadDailyRatingSurveyAnswers} from '../helpers/DataService';
import NavigationBar from '../components/NavigationBar';

export default function () {
    const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswer[]>();

    useInitializeView(() => {
        loadDailyRatingSurveyAnswers('BD_Q4_Fatigue').then(surveyAnswers => {
            setSurveyAnswers(surveyAnswers);
        });
    });
    
    console.log(surveyAnswers)

    return (
        <div className="fatigue view">
            <NavigationBar/>
            <div className="title">Fatigue</div>
            <div className="content-container">
                <Chart data={surveyAnswers ? [buildRatingChartDataSeries(surveyAnswers, 'fatigue')] : undefined} yAxisLabel='Rating'/>
            </div>
        </div>
    );
}