import React, { useState } from 'react'
import './PainInterim.css';
import '../Core.css';
import MyDataHelps, { ParticipantInfo, SurveyAnswersPage, SurveyAnswersQuery } from '@careevolution/mydatahelps-js';
import { LoadingIndicator } from '@careevolution/mydatahelps-ui';
import Cta from '../components/Cta';
import { useInitializeView } from "../helpers/Initialization";
import NavigationBar from '../components/NavigationBar';

export default function () {

        const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
        const [showInfographics, setShowInfographics] = useState<boolean>(false);
    
        useInitializeView(() => {
            MyDataHelps.getParticipantInfo().then(participantInfo => {
                setParticipantInfo(participantInfo);
            });
    
            let surveyAnswersQuery: SurveyAnswersQuery = {
                surveyName: ['Feasibility_Visit1 (Baseline)', 'Edu_Visit1'],
                limit: 1
            };
            MyDataHelps.querySurveyAnswers(surveyAnswersQuery).then((result: SurveyAnswersPage) => {
                setShowInfographics(result.surveyAnswers.length > 0);
            });
        });
    
        

    const isSymptomTracked = (symptom: string) => {
        let customFields = participantInfo?.customFields;
        return customFields && customFields.hasOwnProperty(symptom) && customFields[symptom] === "True";
    };

    const onModuleClicked = (module: string) => {
        MyDataHelps.openApplication(new URL(module, document.location as any).href, {modal: true});
    };

    const onInfographicsClicked = () => {
        MyDataHelps.openApplication(new URL('infographics', document.location as any).href, {modal: true});
    };


    
    return (
        <div className="pain interim view">
            <NavigationBar/>
            <div className="title">Choose which variable you would like to display</div>
            {!participantInfo &&
            <LoadingIndicator/>
            }
            {participantInfo &&
            <div className="dashboard-toc">
                <Cta icon="images/pain.svg" text="Pain Severity" onClick={() => onModuleClicked('pain')}/>
                <Cta icon="images/pain.svg" text="Pain Interference" onClick={() => onModuleClicked('pain-interference')}/>
                {showInfographics &&
                <Cta icon="images/info.svg" className="cta-fa" text="Infographics" onClick={() => onInfographicsClicked()}/>
                }

            </div>
            }
        </div>
    )
}
