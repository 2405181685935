import React, {useState} from 'react'
import './Chart.css';
import '../Core.css';
import {LoadingIndicator} from '@careevolution/mydatahelps-ui';
import {Bar, CartesianGrid, ComposedChart,  ResponsiveContainer, XAxis, YAxis} from 'recharts';
import moment, {Moment} from "moment";


export interface ChartDataEntry {
    date: Moment;
    value: number | null;
  }
  
  export interface ChartDataSeries {
    id: string;
    color: string;
    entries: ChartDataEntry[];
    fillGaps?: boolean;
  }
  
  export interface ChartProps {
    data?: ChartDataSeries[];
    yAxisLabel: string;
    type?: 'line' | 'bar-line';
  }
  
  export default function Chart(props: ChartProps) {
    const daysInWeek = 7;
    const daysInMonth = 30;
    const daysInYear = 365;
    const daysInYearAggregate = 7;
  
    const [daysToRender, setDaysToRender] = useState<number>(daysInWeek);
  
    let today = moment().startOf('day');
    let startDate = moment(today).subtract(daysToRender -1, 'days');
    let endDate = moment(today);
    let trimmedData: ChartDataSeries[] = [];
  
    let rechartsData: any = [];
    let maxValue = 0;
  
    // Declare xTickDates here
    const xTickDates: string[] = [];
    
    console.log(endDate)
    for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
      xTickDates.push(date.format('YYYY-MM-DD'));
    }
  
    if (props.data) {
      // Trim the data to the visible date range.
      for (let x = 0; x < props.data.length; x++) {
        let series: ChartDataSeries = props.data[x];
        let trimmedSeries: ChartDataSeries = {
          id: series.id,
          color: series.color,
          entries: [],
          fillGaps: series.fillGaps,
        };
        for (let y = 0; y < series.entries.length; y++) {
          let entry: ChartDataEntry = series.entries[y];
          if (entry.date.isSameOrAfter(startDate) && entry.date.isSameOrBefore(endDate)) {
            trimmedSeries.entries.push(entry);
          }
        }
        trimmedData.push(trimmedSeries);
      }
  
      // Fill in the gaps with values of 0.
      for (let x = 0; x < xTickDates.length; x++) {
        let date = moment(xTickDates[x]);
        for (let y = 0; y < trimmedData.length; y++) {
          let series = trimmedData[y];
          if (!series.entries.find((e) => e.date.isSame(date))) {
            series.entries.push({
              date: date,
              value: series.fillGaps ? 0 : null,
            });
          }
        }
      }
  
      // Sort the entries.
      for (let x = 0; x < trimmedData.length; x++) {
        trimmedData[x].entries.sort((a, b) => a.date.diff(b.date));
      }
  
      // Aggregate the data when displaying a year.
      if (daysToRender === daysInYear) {
        let aggregatedData: ChartDataSeries[] = [];
        for (let x = 0; x < trimmedData.length; x++) {
          let series = trimmedData[x];
          let aggregatedSeries: ChartDataSeries = {
            id: series.id,
            color: series.color,
            entries: [],
            fillGaps: series.fillGaps,
          };
          for (let i = 0; i < daysInYear; i += daysInYearAggregate) {
            let entryIndex = series.entries.length - (1 + i);
  
            let total = 0;
            let daysWithValue = 0;
            for (let j = 0; entryIndex - j >= 0 && j < daysInYearAggregate; j++) {
              if (series.entries[entryIndex - j].value) {
                total += series.entries[entryIndex - j].value!;
                daysWithValue++;
              }
            }
  
            aggregatedSeries.entries.unshift({
              date: series.entries[entryIndex].date,
              value:
                daysWithValue > 0
                  ? total / daysWithValue
                  : series.fillGaps
                  ? 0
                  : null,
            });
          }
          aggregatedData.push(aggregatedSeries);
        }
        trimmedData = aggregatedData;
      }
  
      // Convert to the Recharts data format.
      for (let x = 0; x < trimmedData.length; x++) {
        let series: ChartDataSeries = trimmedData[x];
  
        for (let y = 0; y < series.entries.length; y++) {
          let entry: ChartDataEntry = series.entries[y];
          let existingData = rechartsData.find((d: any) =>
            d.date.isSame(entry.date)
          );
          if (existingData) {
            // let randomValue = Math.floor(Math.random() * 10) + 1;
            existingData[series.id] = entry.value;
          } else {
            let newData: any = { date: entry.date };
            newData[series.id] = entry.value;
            rechartsData.push(newData);
          }
          if (entry.value && entry.value > maxValue) {
            maxValue = entry.value;
          }
        }
      }
  
      rechartsData.sort((a: any, b: any) => a.date.diff(b.date));
    } 
  
    const onWeekSelected = () => {
      setDaysToRender(daysInWeek);
    };
  
    const onMonthSelected = () => {
      setDaysToRender(daysInMonth);
    };
  
    const onYearSelected = () => {
      setDaysToRender(daysInYear);
    };
    //add the colors
    const colors = ['#575294', '#afa9ee'];
    console.log(rechartsData)

    return (
        <div>
            <div className="chart-period-selector">
                <div className={'chart-period' + (daysToRender === daysInWeek ? ' chart-period-selected' : '')} onClick={() => onWeekSelected()}>Week</div>
                <div className="chart-period-divider">&nbsp;</div>
                <div className={'chart-period' + (daysToRender === daysInMonth ? ' chart-period-selected' : '')} onClick={() => onMonthSelected()}>Month</div>
                <div className="chart-period-divider">&nbsp;</div>
                <div className={'chart-period' + (daysToRender === daysInYear ? ' chart-period-selected' : '')} onClick={() => onYearSelected()}>Year</div>
            </div>
            {props.data && (
                <div className="chart">
                    {/* Bar Chart */}
                    {(!props.hasOwnProperty('type') || (props.hasOwnProperty('type') && props.type === 'bar-line')) && (
                        <ResponsiveContainer width="100%" height={window.innerWidth * 3 / 5}>
                            <ComposedChart data={rechartsData} margin={{ top: 20, right: 16, left: props.yAxisLabel ? 30 : 10 }}>
                                <CartesianGrid fill="#FFF" />
                                <XAxis dataKey={(entry) => entry.date.format('M/D')} fontSize={12} tick={true} />
                                <YAxis fontSize={12} width={24} label={{ value: props.yAxisLabel, angle: -90, position: 'left', textAnchor: 'middle', offset: 20 }} />
                                {/* Bar color is set to blue requested by PIs */}
                                {trimmedData.map((series, index) => (
                                    <Bar key={index.toString() + '-bar'} dataKey={series.id} barSize={20} fill={colors[index]} />
                                ))}
                            </ComposedChart>
                        </ResponsiveContainer>
                    )}
                </div>
            )}
            {!props.data && <LoadingIndicator />}
        </div>
    );
}