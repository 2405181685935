import React from 'react'
import './Cta.css';
import '../Core.css';

export interface CtaProps {
    icon: string;
    text: string;
    onClick?: () => void;
    className?: string;
}



export default function (props: CtaProps) {

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div className={'cta ' + (props.className || '')} onClick={() => onClick()}>
            <div className="cta-icon">
                <img src={props.icon} alt="cta icon"/>
            </div>
            <div className="cta-text">{props.text}</div>
        </div>
    )
}