import React, {useState} from 'react'
import './Depression.css';
import '../Core.css';
import {SurveyAnswer} from '@careevolution/mydatahelps-js';
import Chart from '../components/Chart';
import {useInitializeView} from '../helpers/Initialization';
import {buildRatingChartDataSeries} from '../helpers/ChartData';
import {loadDailyRatingSurveyAnswers} from '../helpers/DataService';
import NavigationBar from '../components/NavigationBar';

export default function () {
    const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswer[]>();

    useInitializeView(() => {
        loadDailyRatingSurveyAnswers('BD_Q5_Depression').then(surveyAnswers => {
            setSurveyAnswers(surveyAnswers);
        });
    });

    return (
        <div className="depression view">
            <NavigationBar/>
            <div className="title">Depression</div>
            <div className="content-container">
                <Chart data={surveyAnswers ? [buildRatingChartDataSeries(surveyAnswers, 'depression')] : undefined} yAxisLabel='Rating'/>
            </div>
        </div>
    );
}