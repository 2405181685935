import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './views/Dashboard';
import Tasks from './views/Tasks';
import Pain from './views/Pain';
import PainInterim from './views/PainInterim';
import PainInterference from './views/PainInterference';
import Anxiety from './views/Anxiety';
import Depression from './views/Depression';
import Fatigue from './views/Fatigue';
import Steps from "./views/Steps";
import MoodInterim from './views/MoodInterim';

if (window.location.hostname === 'localhost' && !!process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN) {
    window.MyDataHelps.setParticipantAccessToken({ 'access_token': process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN, 'expires_in': 21600, 'token_type': 'Bearer' }, 'https://mydatahelps.org/');
}

let classNames = [];
if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

let html = document.getElementsByTagName('html')[0];

if (classNames.length) classNames.push('on-device');
if (html.classList) html.classList.add.apply(html.classList, classNames);

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<Tasks/>}/>
                <Route path="/tasks/" element={<Tasks/>}/>
                <Route path="/dashboard/" element={<Dashboard/>}/>
                <Route path="/pain-interim/" element={<PainInterim/>}/>
                <Route path="/pain-interference/" element={<PainInterference/>}/>
                <Route path="/pain/" element={<Pain/>}/>					
                <Route path="/fatigue/" element={<Fatigue/>}/>
                <Route path="/mood-interim/" element={<MoodInterim/>}/>
                <Route path="/depression/" element={<Depression/>}/>
                <Route path="/anxiety/" element={<Anxiety/>}/>
                <Route path="/steps/" element={<Steps/>}/>
              
            </Routes>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
