import {SurveyAnswer} from '@careevolution/mydatahelps-js';
import {Moment} from 'moment';
import {ChartDataSeries} from '../components/Chart';

const moment = require('moment');

export interface Ratings {
    date: Moment;
    total: number;
    count: number;
}

export function buildRatingChartDataSeries(surveyAnswers: SurveyAnswer[], seriesId: string, color: string = '#575294'): ChartDataSeries {
    let ratings = surveyAnswers.reduce((ratings: Ratings[], surveyAnswer: SurveyAnswer) => {
        let date = moment(surveyAnswer.date).startOf('day');
        let entry = ratings.find(e => e.date.isSame(date));
        if (!entry) {
            entry = {date: date, total: 0, count: 0};
            ratings.push(entry);
        }
        entry.total += parseInt(surveyAnswer.answers[0]);
        entry.count += 1;
        return ratings;
    }, [])

    return {
        id: seriesId,
        color: color,
        entries: ratings.map((ratings: Ratings, index) => {
            return {
                date: ratings.date,
                value: ratings.total / ratings.count
            };
        })
    };
}

